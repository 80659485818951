<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-15 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>
				<div class="container mt-10">
					<div class="subindex_box_1">
						<span class="cell_group_tit">기본 알림</span>
						<ul class="settings_list">
							<li class="settings_list_item">
								<div class="cell_tit">공지사항 알림</div>
								<button @click="postNoticeAlram">
									<div class="cell_area">
										<div class="cell_action">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span
													class="btn-toggle"
													:class="{ on: user.notice_note_fg == 'Y' }"
												><em class="hide">공지사항 알림</em></span>
											</p>
										</div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
                <div class="cell_tit">스테이킹 내역 알림</div>
								<button @click=" postStakingAlram">
									<div class="cell_area">
										<div class="cell_action">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span
													class="btn-toggle"
													:class="{ on: user.stakg_note_fg == 'Y' }"
												><em class="hide">공지사항 알림</em></span>
											</p>
										</div>
									</div>
								</button>
							</li>
							<li
								class="settings_list_item cursor-pointer"
								@click="friends_popup = true"
							>
                <div class="cell_tit">친구 알림</div>
								<button>
									<div class="cell_area">
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
      <hr class="under-line mt-20"/>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">카르텔 알림</span>
						<ul class="settings_list">
<!--							<li
								class="settings_list_item"
								@click="$emit('to', { name: 'mafia107', params: { type: 'notice'}})"
							>-->
              <li
                  class="settings_list_item cursor-pointer"
                  @click="notice_popup = true"
              >
                <div class="cell_tit">공지글 알림</div>
								<button>
									<div class="cell_area">
										<div class="cell_action"><span v-if="false" class="text">{{ count_notice }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
      <hr class="under-line mt-10"/>
			<div
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<span class="cell_group_tit">내 소식 알림 항목을 설정하세요.</span>
						<ul class="settings_list">
<!--							<li
								class="settings_list_item"
								@click="$emit('to', { name: 'mafia107', params: { type: 'comment'}})"
							>-->
              <li
                  class="settings_list_item cursor-pointer"
                  @click="comment_popup = true"
              >
                <div class="cell_tit">댓글 알림</div>
								<button>
									<div class="cell_area">
										<div class="cell_action"><span v-if="false" class="text">{{ count_comment }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
      <hr class="under-line mt-20"/>
		</div>

    <Friends
        v-if="friends_popup"
        :user="user"

        @cancel="friends_popup = false"
    ></Friends>

    <NoticeNotification
        v-if="notice_popup"
        :user="user"

        @cancel="notice_popup = false"
    ></NoticeNotification>

    <CommentNotification
        v-if="comment_popup"
        :user="user"

        @cancel="comment_popup = false"
    ></CommentNotification>
	</div>
</template>

<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
import Friends from "@/view/Mypage/popup/Friends";
import NoticeNotification from "@/view/Mypage/popup/NoticeNotification";
import CommentNotification from "@/view/Mypage/popup/CommentNotification";
	export default {
		name: 'mafia105'
		, props: ['Axios', 'user']
		, components: {Friends, MypageSide, NoticeNotification, CommentNotification }
		, data: function(){
			return {
				program: {
					name: '알림'
					, title: '알림'
					, not_footer: true
					, type: 'mypage'
					, from: 'myPage'
					, bg_contents: 'bg-gray01'
				}
				,item: {
					is_staking: false
					,is_notice: false
				}
				, items_cartel: []
        , friends_popup: false
        , notice_popup: false
        , comment_popup: false
			}
		}
		, computed: {
			count_notice: function(){
				let t = 0

				for(let item of this.items_cartel){
					if(item.is_notice){
						t++
					}
				}

				return t
			}
			, count_comment: function(){
				let t = 0

				for(let item of this.items_cartel){
					if(item.is_comment){
						t++
					}
				}

				return t
			}
			, is_app: function(){
				let t = false
				return t
			}
		}
		,methods: {
			postStakingAlram: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_staking_alram
						, data: {
							member_number: this.user.member_number
							, stakg_note_fg: this.user.stakg_note_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})
					if(result.success){
						this.user.stakg_note_fg = this.user.stakg_note_fg == 'Y' ? 'N' : 'Y'
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postNoticeAlram: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice_alram
						, data: {
							member_number: this.user.member_number
							, notice_note_fg: this.user.notice_note_fg == 'Y' ? 'N' : 'Y'
						}
						, type: true
					})
					if(result.success){
						this.user.notice_note_fg = this.user.notice_note_fg == 'Y' ? 'N' : 'Y'
						this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			//this.getData()
			//this.getMyCartel()
		}
	}
</script>
<style>
.cell_group_tit { padding: 20px 0px 16px 0; display: block; font-size: 1.1rem; color: var(--gray02); line-height: 1.4rem; }
.settings_list_item { position: relative; width: 100%; display: flex; line-height: 30px; background-color: transparent; justify-content: space-between}
.icon {
	background-color: white;
}

.cell_tit{font-weight: 500;}
.settings_list_item:nth-child(n+1){margin-bottom: 16px;}
.settings_list_item:nth-last-child(1){margin-bottom: 0;}
</style>