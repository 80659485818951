<template>
  <div>
    <PopupLayer>
      <template
          v-slot:body
      >
        <div
            class="flex-column radius-20 text-left  radius-20 overflow-hidden"
            style="min-width: 320px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
        >
          <div class="bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
            <h4 class="color-white">공지사항 알림</h4>

            <img class="cursor-pointer"
                    @click="$emit('cancel')"
                    :src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
            />
          </div>

          <div class="bg-white">

            <div class="pa-13-30">
              <div class="content_wrap">
                <span class="cell_group_tit" v-if="items_cartel.length > 0">카르텔</span>
                <ul
                    v-if="items_cartel.length > 0"
                    class="settings_list mr-20"
                >
                  <li
                      v-for="(item, index) in items_cartel"
                      :key="'item_' + index"
                      class="settings_list_item"
                  >
                    <div class="cell_tit">{{ item.cartl_name }}</div>
                    <div class="cell_area" style="height:30px;">
                      <div class="cell_action">
                        <p
                            class="toggle-group"
                            @click="postNoticePush(item)"
                        >
                          <!-- TODO : 수신 허용시 .on 클래스 추가 -->
                          <span
                              class="btn-toggle"
                              :class="{ 'on': item.notice_note_fg == 'Y'}"
                          ><em class="hide">{{ item.cartl_name }}</em></span>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>

                <div
                    v-else
                    class="pa-10 flex-column justify-center items-center" style="transform: translateY(50%);"
                >
                  <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
                  <div class="mt-20">가입된 카르텔이 없습니다.</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
    </PopupLayer>

  </div>

</template>
<script>

import PopupLayer from "@/view/Layout/PopupLayer";

export default {
  name: "NoticeNotification",
  components: {PopupLayer},
  props: ['user'],
  data() {
    return {
      items_cartel: [],
      is_confirm: false,
      item_block: {},
    }
  },

  methods: {
    getNotice: async function () {

      try {
        this.$bus.$emit('on', true)

        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_my_cartel_notice_alram
          , data: {
            member_number: this.user.member_number
          }
          , type: true
        })

        if (result.success) {
          this.items_cartel = result.data.notice_cartl_list
        } else {
          throw result.message
        }

        /*this.items_cartel.push({cartl_name: 'dqwdwq', notice_note_fg: 'N'})*/

      } catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.is_search = true
        this.$bus.$emit('on', false)
      }
    }

    , postNoticePush: async function(item){

      try{
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_cartel_notice_alram
          , data: {
            member_number: this.user.member_number
            , cartl_number: item.cartl_number
            , notice_note_fg: item.notice_note_fg == 'Y' ? 'N' : 'Y'
          }
          , type: true
        })

        if(result.success){
          await this.getNotice()
        }else{
          throw result.message
        }
      }catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }
    }
  }

  , async created() {
    await this.getNotice()
  }
}
</script>

<style scoped>
li {
  display: flex;
  column-gap: 10px;
  height: 30px;
  align-items: center;
}

img {
  border-radius: 50%;
}
.content_wrap {
  height: 350px;
  overflow-y: auto;
}

.content_wrap::-webkit-scrollbar{
  width:4px;
}
.content_wrap::-webkit-scrollbar-thumb{
  background: #bdbdbd;
  border-radius: 10px;
}

.content_wrap::-webkit-scrollbar-track{
  background: #e9e9e9;
  border-radius: 10px;
}
.settings_list_item {
  position: relative;
  width: 100%;
  display: flex;
  line-height: 4rem;
  background-color: transparent;
  justify-content: space-between;
  align-items: center
}
</style>
