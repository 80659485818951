<template>
  <div>
    <PopupLayer>
      <template
          v-slot:body
      >
        <div
            class="flex-column radius-20 text-left  radius-20 overflow-hidden" 
            style="min-width: 320px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
        >
            <div class="bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
                <h4 class="color-white">친구</h4>

                <img class="cursor-pointer"
                    @click="$emit('cancel')"
                    :src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
                />
          </div>

          <div class="bg-white">
            <div class="pa-13-30 flex-row under-line">
              <div class="flex-1">
                <input
                    type="text" placeholder="친구 검색"
                    maxlength="20"
                    v-model="keyword"
                    v-on:keyup.enter="getMyFriend"
                    style="width: 100%"
                >
              </div>

              <button @click="keyword = ''">
                <v-icon
                    class="color-dadada"
                >mdi-close-circle
                </v-icon>
              </button>
              <button @click="getMyFriend">
                <v-icon
                    class="ml-10 color-gray"
                >mdi-magnify
                </v-icon>
              </button>
            </div>

            <div class="pa-10-20">
              <div class="content_wrap">
                <!--              <div class="flex-row justify-space-between items-center">
                                <h3>친구</h3>
                                <span>111명</span>
                              </div>-->

                <ul class="mt-10 mr-20">
                  <li
                      v-for="(item, index) in items_friend"
                      :key="'item_' + index"
                  >
                    <div class="picture">

                      <img
                          v-if="item.profle_nft_card_img_url"
                          :src="$request.upload_url(item.profle_nft_card_img_url)"
                          referrerpolicy="no-referrer"
                      >
                      <img
                          v-else
                          :src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
                          referrerpolicy="no-referrer"
                      />
                    </div>
                    <div class="text_area">
                      <strong class="font-weight-500">{{ item.nickname }}</strong>
                      <p class="textzone textzone color-888 mt-5">
                        {{ item.state_message }}
                      </p>
                    </div>

                    <div class="" style="cursor: pointer" @click="postAlarm(item)">
                      <img
                          v-if="item.note_fg == 'Y'"
                          :src="require('@/assets/image/web/mypage_cartel/ico_alarm_on.svg')"
                      >
                      <img
                          v-else
                          :src="require('@/assets/image/web/mypage_cartel/ico_alarm_off.svg')"
                      >
                    </div>
                    <div class="ct_invite_btn">
                      <button
                          class="btn-inline radius-30 pa-10-20 size-px-12 font-weight-500"
                          @click="onConfirm(item)"
                          :disabled="false"
                      >취소
                      </button>
                    </div>
                  </li>
                </ul>
                <div
                    v-if="items_friend.length <= 0"
                    class="pa-10 flex-column justify-center items-center"
                    style="transform: translateY(50%)"
                >
                  <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
                  <div class="mt-20">친구를 조회할 수 없습니다.</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
    </PopupLayer>

    <PopupConfirm
        v-if="is_confirm"

        @click="postFriendCancel"
        @cancel="is_confirm = false"
        
    >
      <template
          v-slot:title
      >친구 취소
      </template>
      <template
          v-slot:main-txt
      >친구 취소 하시겠습니까?
      </template>
      <template
          v-slot:sub-txt
      >친구 취소 하시려면 확인을 눌러주세요.
      </template>
    </PopupConfirm>
  </div>

</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
import PopupConfirm from "@/view/Layout/PopupConfirm";

export default {
  name: "Friends",
  components: {PopupLayer, PopupConfirm},
  props: ['user'],
  mounted() {
    this.getMyFriend()
  },
  data() {
    return {
      items_friend: [

      ]

      , is_confirm: false
      , item_friend: {}
      , keyword: ''
    }
  },

  methods: {
    getMyFriend: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_friends_list
          , data: {
            member_number: this.user.member_number
            , srchtext: this.keyword
            , page_number: this.$language.base.page_number
            , pagerecnum: this.$language.base.pagerecnum
          }
          , type: true
        })

        if (result.success) {
          this.items_friend = result.data.friend_list
        } else {
          throw result.message
        }

      } catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.is_search = true
        this.$bus.$emit('on', false)
      }
    },

    postAlarm: async function (friend) {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_friend_alarm
          , data: {
            member_number: this.user.member_number
            , friend_member_number: friend.friend_member_number
            , note_fg: friend.note_fg == 'Y' ? 'N' : 'Y'
          }
          , type: true
        })

        if (result.success) {
          friend.note_fg = friend.note_fg == 'Y' ? 'N' : 'Y'
        } else {
          throw result.message
        }
      } catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , postFriendCancel: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.post_friend_cancel
          , data: {
            member_number: this.user.member_number
            , friend_member_number: this.item_friend.friend_member_number
          }
          , type: true
        })

        this.$log.console('postFriend result ', result)
        if (result.success) {
          this.$bus.$emit('notify', {type: 'success', message: this.$language.common.success})
          await this.getMyFriend()
        } else {
          throw result.message
        }
      } catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.is_confirm = false
        this.$bus.$emit('on', false)
      }
    }
    , onConfirm: function (item_friend) {
      this.item_friend = item_friend
      this.is_confirm = true
    }


  }
}
</script>

<style scoped>
li {
  display: flex;
  column-gap: 10px;
  margin-top: 15px;
  align-items: center;
  /* height: 50px; */
}

.text_area {
  width: 320px;
}

img {
  /* border-radius: 50%; */
}

.btn-inline {
  background-color: white;
  color: var(--blue02);
  border: 1px solid var(--blue02); /* Green */
  transition: 0.2s all;
}

.btn-inline:hover {
  background-color: var(--blue02) !important;
  color: white;
}

.content_wrap {
  height: 350px;
  overflow-y: auto;
}

.content_wrap::-webkit-scrollbar{
  width:4px;
}
.content_wrap::-webkit-scrollbar-thumb{
  background: #bdbdbd;
  border-radius: 10px;
}

.content_wrap::-webkit-scrollbar-track{
  background: #e9e9e9;
  border-radius: 10px;
}
.picture img{
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50px;
}
</style>